// DH

// Estimate ad sizes

.container-BottomMPU
{
  min-height: 90px;
  max-height: 250px;

  @include media-breakpoint-down("xxl") {
    height: 250px;
  }
}

.container-BottomLeaderboard
{
	min-height: 90px;
	max-height: 250px;

  @include media-breakpoint-down("lg") {
    max-width: 300px;
    max-height: 600px;
		height: 600px;
    margin-left: auto;
    margin-right: auto;
    background-color: $dw-gray-bg;

    > div {
      position: -webkit-sticky;
      position: sticky;
      top: calc(60px + 2rem);
    }
	}
}

@include media-breakpoint-up("md") {

	.dw-protect-cls
	{
		position: relative;
		min-height: 90px;
		max-height: 250px;
		/* height: 250px; */

    &.dw-billboard-body {
      min-height: 90px;
      max-height: 90px;
      height: 90px;

      @include media-breakpoint-up("lg") {
        min-height: 250px;
        max-height: 250px;
        height: 250px;
      }
    }

    &.dw-billboard-header
    {
      min-height: calc(90px + 2rem);
      max-height: calc(90px + 2rem);
      height: calc(90px + 2rem);
      padding-top: 1rem;
      padding-bottom: 1rem;

      @include media-breakpoint-up("lg") {
        min-height: calc(250px + 2rem);
        max-height: calc(250px + 2rem);
        height: calc(250px + 2rem);
      }
    }
	}

	.container-ArticleLeaderboard
	{
		min-width: 728px;
		min-height: 90px;
		max-height: 250px;
  }

  @include media-breakpoint-up("lg") {

    .container-ArticleLeaderboard {
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .container-ArticleMediumRectangle
    {
      width: 300px;
      min-height: 250px;
      max-height: 1050px;
      position: relative;
    }
  }

}

// Background for loading ads

div[id^="div-gpt-ad-1321481451196-"] iframe
{
  overflow: auto;
}

div[id^="google_ads_iframe_/86406173/"]
{
  .container-ArticleMediumRectangle &
  {
    position: absolute;
  }

  &::after {
    content: 'Advertisement';
    display: block;
    font-size: .7rem;
    text-transform: uppercase;
    opacity: .7;

    .dw-billboard-header & {
      color: $white;
    }
  }
}

.container-ArticleMediumRectangle {

  iframe
  {
    background-color: $dw-gray-bg;
  }

}

.dw-billboard-header
{
  background-color: $dw-color;
  border-top: 1px dashed $dw-color-light;
}
